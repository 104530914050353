import React from "react"
import {Button} from "../"
import * as s from "./notFound.module.css"

const NotFound = () => (
  <section className={s.wrapper}>
    <h1 className={s.title}>404: Not Found</h1>
    <p className={s.description}>Sorry, we can't find this page. Maybe try another one?</p>
    <Button label={"Go Home"} to={"/"} />
  </section>
)

export default NotFound