import React from "react";
import {Layout} from "../components";
import Seo from "../components/seo/seo";
import NotFound from "../components/notFoundPage/notFound";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <NotFound />
  </Layout>
)

export default NotFoundPage
